<template>
  <div class="page-layout contacts">

    <HeaderIntroImage underlined backstep :headerMetadata="pageData"></HeaderIntroImage>

    <div class="container">
      <div class="step gray-bg">

        <div class="form--info">
          <h2>Send us an e-mail</h2>
          <h4>Contact us</h4>
          <p class="icon-left email">
            <a class="email plain" href="mailto:info@qtedu.eu">info@qtedu.eu</a>
          </p>
          <br>
          <h4>Contact us regarding the pilot project</h4>
          <p class="icon-left email">
            <a class="email plain" href="mailto:pilots.qtedu@qtedu.eu">pilots.qtedu@qtedu.eu</a>
          </p>
        </div>

        <br>
        <br>

        <!-- <form class="contact-form" @submit.prevent>
          <div>
            <div class="campo">
              <label for="name" class="label bold">NAME & SURNAME</label>
              <input required type="text" name="name" placeholder="Name and Surname">
            </div>
            <div class="campo">
              <label for="email" class="label bold">MAIL</label>
              <input required type="email" name="email" placeholder="Email">
            </div>
            <vue-recaptcha class="recaptcha" ref="recaptcha" :sitekey="sitekey"
              @verify="onverify" @error="onerror" @expired="onexpired">
            </vue-recaptcha>
          </div>
          
          <div class="campo">
            <label for="message" class="label bold">WRITE YOUR MESSAGE</label>
            <textarea name="message" id="" rows="10" placeholder="Write your message here ..."></textarea>
            <br>
            <button class="btn slim"><span>Send</span></button>
          </div>
        </form> -->

      </div>
    </div>
  </div>
</template>

<script>
//import { VueRecaptcha } from 'vue-recaptcha'
import HeaderIntroImage from '../components/layout-blocks/HeaderIntroImage.vue'
export default {
  name: 'contacts',
  components: {
    HeaderIntroImage,
    //VueRecaptcha
  },
  props: {},
  data: () => {
    return {
      pageData: null,
      formData: {
        email: '',
        name: '',
        message: '',
        robot: false
      },
      error: false,
      errorMsg: ''
    }
  },
  computed: {
    lang() {
      return this.$store.state.currentLang
    },
    defaultLang() {
      return this.$store.state.defaultLang
    },
    sitekey() {
      return process.env.VUE_APP_RECAPTCHA
    }
  },
  methods: {
    onverify() {
     //console.log('recaptcha - VERIFIED')
      this.formData.robot = true
    },
    onexpired() {
     //console.log('recaptcha - EXPIRED')
      this.formData.robot = false
    },
    onerror() {
     //console.log('recaptcha - ERROR')
      this.formData.robot = false
    },
    submit() {
      if(this.formData.robot) {
        //SUBMIT FORM
       //console.log('SUBMIT FORM HERE')
      }
    }
  },
  mounted() {
    this.pageData = {
      attributes: {
        title: "Contacts",
        subtitle: "",
        tag: ""
      }
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.contacts .header-intro { padding: 3rem 0rem; }
.backstep + .container .step { padding: 1rem; }
.form--info { width: 100%; }

.error-message {
  color: #FF0576; 
  margin: 1em 0; 
  font-size: 1rem;
}

.recaptcha ::v-deep div {
  margin: 1em auto 0 auto;
  max-width: 100% !important;
  * { max-width: 100% !important; }
}

.contact-form {
  display: block;
  gap: 1em;
  .campo { margin: 1em 0; }
  .btn { margin: 0 0 0 auto; }
}

.backimage {
  padding: 2em;
  display: flex;
  flex-direction: column;

  background-image: url('/block_conference.jpg');
  background-size: cover;

  p { font-size: .95em; }
}

button[type=submit] { max-height: 2.25em; }

.accordion {
  background: transparent;
  border: none;
  outline: none;
  text-align: left;
  font-size: 1rem;
  padding: .5em 0;
  margin-bottom: 1rem;
  width: fit-content;
  &:hover { text-decoration: underline; }
}

@media (min-width: 768px) {
  .recaptcha ::v-deep div { margin: 1em 0 0 0; }
}

@media (min-width: 1024px) {
  .contacts {
    .backstep + .container .step:first-child {
      padding-left: 4em;
      padding-right: 4em;
    }
    .header-intro.backstep { padding-bottom: calc(2rem + 120px); }
  }

  .recaptcha ::v-deep div { margin: 0; }
  .error-message { margin: 0 0 1em 0; }

  .contact-form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1em 2em;
    .btn { margin: 0 0 0 auto; }
  }
}
</style>